import React from 'react';
import {googleScriptLoadingDelay} from "../../utils/articleUtil";
import {isMobile} from "react-device-detect";

class AddhisPopular extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            try {
                window.addthis.layers.refresh();
            } catch (e) {
                console.log(e);
            }
        }, googleScriptLoadingDelay() * (isMobile ? 3 : 1));
    }

    render() {
        return <div className="addthis_relatedposts_inline"/>
    }
}

export default AddhisPopular;
